<template>
  <a-card title="角色管理" class="role__wrapper">
    <!-- <role-header ref="header" @refresh="$refs['table'].initPage()" /> -->
    <div class="table_btn">
      <a-button type="primary" @click="$refs.form.createModel()" v-perms="'add'">新增</a-button>
    </div>
    <p-table
      ref="table"
      rowKey="roleId"
      :columns="columns"
      :sourceData="getDataApi"
      :scroll="{y: 100}"
      extraHeight="380"
    >
      <template slot="level" slot-scope="text">{{ formatLevel(text) }}</template>
      <span slot="action" slot-scope="text, record">
        <a-space v-if="currentlevel < record.level">
          <a-button type="link" @click="updateForm(record.roleId)" v-perms="'update'">编辑</a-button>
          <a-button type="link" @click="openPermission(record)" v-perms="'permisstion'">授权</a-button>
          <a-popconfirm title="确认删除吗?" ok-text="是" cancel-text="否" @confirm="del(record.roleId)" v-perms="'delete'">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </a-space>
      </span>
    </p-table>
    <role-form ref="form" @success="$refs.table.refresh()" />
    <permission ref="permission" />
  </a-card>
</template>

<script>
import { roleDelete, rolePage } from '@/api/employmentUser/role'
import { STable } from '@/components'
import RoleForm from '@/views/employmentUser/form/RoleForm'
import RoleHeader from '@/views/employmentUser/tableHeader/RoleHeader'
import { mapGetters } from 'vuex'
import Permission from '@/views/employmentUser/role/permission'
import appConfig from '/config/app.config'
import { getInfo } from '@/api/login'
export default {
  name: 'Role',
  components: {
    Permission,
    RoleHeader,
    RoleForm,
    STable
  },
  data () {
    return {
      currentlevel: '',
      clientId: '',
      columns: [
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '角色级别',
          dataIndex: 'level',
          scopedSlots: { customRender: 'level' }
        },
        {
          title: '操作',
          key: 'action',
          width: 240,
          align: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.query)
        return rolePage(requestParameters).then(res => {
          return res
        })
      },
      params: {},
      roleType: []
    }
  },
  computed: {
    ...mapGetters('employmentUser/role', ['query'])
  },
  mounted () {
    this.roleType = this.handleGetDict('roleType')
    this.init()
  },
  methods: {
     init () {
      getInfo().then(res => {
        this.currentlevel = res.data.roleInfo[0].level
      })
    },
    updateForm (id) {
      this.$refs.form.editModel(id)
    },
    openPermission (row) {
      this.clientId = row.clientId
      this.$refs.permission.init(row.roleId, appConfig.clientId)
    },
    del (id) {
      roleDelete({ id }).then(res => {
        if (res.success) {
          this.$refs['table'].refresh()
        }
      })
    },
    getDataApi (params) {
      return rolePage({
        ...params
      })
    },
    formatLevel (text) {
      return (this.roleType.find(item => item.value == text) || {}).label
    }
  }
}
</script>

<style lang="less">
.role__wrapper {
  .table_btn {
    margin-bottom: 15px;
  }
}
</style>
