<template>
  <a-form-model
    :model="query"
    class="role__form"
    :label-col="{span:4}"
    :wrapper-col="{span: 14}"
    labelAlign="left"
  >
    <a-row>
      <a-col :span="8">
        <a-form-model-item label="角色级别">
        <a-select :options="roleType" v-model="query.level" placeholder="请选择角色级别" allowClear></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <!-- <a-form-model-item label="客户端" placeholder="请选择客户端">
          <a-select v-model="query.clientId">
            <a-select-option value="enterprise">企业端</a-select-option>
            <a-select-option value="platform">平台端</a-select-option>
            <a-select-option value="supplier">供应商端</a-select-option>
          </a-select>
        </a-form-model-item> -->
      </a-col>
      <a-col :span="8">
        <div class="form_btn_box">
          <a-button type="primary" @click="refresh">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
export default {
  name: 'ProductHeader',
  data() {
    return {
      query: {
        clientId: 'enterprise'
      },
      roleType: []
    }
  },
  mounted() {
    this.roleType = this.handleGetDict('roleType')
  },
  methods: {
    reset() {
      this.$set(this, 'query', {
        clientId: 'enterprise'
      })
    },
    refresh() {
      this.$emit('refresh')
    },
    getQuery() {
      return {
        ...this.query
      }
    }
  }
}
</script>

<style lang="less">
.role__form {
  .form_btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
